import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Pagination,
  Modal,
  Spinner,
  Card,
} from "react-bootstrap";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "./"

const App = () => {
  const [events, setEvents] = useState([]);
  const [searchParams, setSearchParams] = useState({
    eventName: "",
    eventDate: "",
    genre: "",
    country: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [scrapingResult, setScrapingResult] = useState(null);
  const [showScrapeModal, setShowScrapeModal] = useState(false);

  const fetchData = async (params, page = 1) => {
    try {
      setLoading(true);
      const response = await axios.get(`https://tmback.vision.dev.br/api/events`, {
        params: {
          ...params,
          page: page,
        },
      });
      setEvents(response.data.events);
      setTotalPages(response.data.totalPages);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(searchParams, currentPage);
  }, [searchParams, currentPage]);

  const handleChange = (e) => {
    setSearchParams({
      ...searchParams,
      [e.target.name]: e.target.value,
    });
    setCurrentPage(1); // Reset to first page on search
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);
      await axios.get("https://tmback.vision.dev.br/api/obter-eventos");
      await fetchData(searchParams, currentPage); // Fetch data after updating
      setLoading(false);
      alert("Data updated successfully");
    } catch (error) {
      console.error("Error updating data:", error);
      setLoading(false);
      alert("Error updating data");
    }
  };

  const handleShowModal = (imageUrl) => {
    setModalImage(imageUrl);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleScraping = async (eventUrl) => {
    try {
      const response = await axios.get("https://tmback.vision.dev.br/api/scrape", {
        params: { url: eventUrl },
      });
      setScrapingResult(response.data);
      setShowScrapeModal(true);
    } catch (error) {
      console.error("Error scraping:", error);
      alert("Error scraping");
    }
  };

  const handleCloseScrapeModal = () => setShowScrapeModal(false);

  return (
    <Container className="mt-1" style={{ backgroundColor: "#f0f0f0", padding: "20px", borderRadius: "8px"}}>
      {/* Card para o título, filtros e botão */}
      <Card className="mb-4" style={{ backgroundColor: "#fff", padding: "20px", borderRadius: "8px" }}>
        <Card.Body>
          <h1 className="text-center">Event Search</h1>
          <Row className="mt-4">
            <Col md>
              <Form.Group controlId="formEventName">
                <Form.Label>Event Name</Form.Label>
                <Form.Control
                  type="text"
                  name="eventName"
                  value={searchParams.eventName}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md>
              <Form.Group controlId="formEventDate">
                <Form.Label>Event Date</Form.Label>
                <Form.Control
                  type="date"
                  name="eventDate"
                  value={searchParams.eventDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md>
              <Form.Group controlId="formGenre">
                <Form.Label>Genre</Form.Label>
                <Form.Control
                  type="text"
                  name="genre"
                  value={searchParams.genre}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md>
              <Form.Group controlId="formCountry">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  as="select"
                  name="country"
                  value={searchParams.country}
                  onChange={handleChange}
                >
                  <option value="">Select a country</option>
                  <option value="US">United States</option>
                  <option value="BR">Brazil</option>
                  <option value="CA">Canada</option>
                  <option value="GB">United Kingdom</option>
                  <option value="DE">Germany</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <div className="text-right">
            <Button variant="secondary" onClick={handleUpdate} style={{ marginTop: "20px" }}>
              Update General Data
            </Button>
          </div>
        </Card.Body>
      </Card>
  
      {/* Card para mostrar os resultados dos eventos */}
      <Card className="mb-4" style={{ backgroundColor: "#fff", padding: "10px", borderRadius: "8px", margin:"5px" }}>
        <Card.Body >
          <h2 className="text-center">Events</h2>
          <div className="d-flex flex-wrap justify-content-center mt-4">
            {events.map((event, index) => (
              <Card
                key={event.eventId || index}
                className="mb-4 mr-4"
                style={{ width: "14rem" }}
              >
                <Card.Img
                  variant="top"
                  src={event.imageUrl || "https://via.placeholder.com/150"}
                />
                <Card.Body>
                  <Card.Title>{event.eventName}</Card.Title>
                  <Card.Text>
                    <strong>Date:</strong> {event.localDate}
                    <br />
                    <strong>Time:</strong> {event.localTimetm && event.localTimetm.substring(0, 5)}
                    <br />
                    <strong>Genre:</strong> {event.attractionGenre} / {event.attractionSubGenre}
                    <br />
                    <strong>City:</strong> {event.venueCity}
                    <br />
                    <strong>Country:</strong> {event.venueCountry}
                  </Card.Text>
                  <Button
                    variant="primary"
                    onClick={() => handleScraping(event.url)}
                  >
                    Scrape Data
                  </Button>
                  <Button
                    variant="link"
                    href={event.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Event
                  </Button>
                </Card.Body>
              </Card>
            ))}
          </div>
        </Card.Body>
      </Card>
  
      {/* Paginação e Modal */}
      <div className="d-flex justify-content-center align-items-center mt-4">
        <Pagination>
          <Pagination.First
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}
          />
          <Pagination.Prev
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {[...Array(totalPages).keys()].slice(0, 5).map((page) => (
            <Pagination.Item
              key={page + 1}
              active={page + 1 === currentPage}
              onClick={() => setCurrentPage(page + 1)}
            >
              {page + 1}
            </Pagination.Item>
          ))}
          {totalPages > 5 && <Pagination.Ellipsis />}
          {totalPages > 5 && (
            <Pagination.Item
              active={currentPage === totalPages}
              onClick={() => setCurrentPage(totalPages)}
            >
              {totalPages}
            </Pagination.Item>
          )}
          <Pagination.Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
          <Pagination.Last
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
        <Form.Control
          as="select"
          value={currentPage}
          onChange={(e) => setCurrentPage(Number(e.target.value))}
          style={{ width: "100px", marginLeft: "10px" }}
        >
          {[...Array(totalPages).keys()].map((page) => (
            <option key={page + 1} value={page + 1}>
              {page + 1}
            </option>
          ))}
        </Form.Control>
      </div>
  
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Event Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={modalImage} alt="Event" style={{ width: "100%" }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showScrapeModal} onHide={handleCloseScrapeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Scrape Result</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {scrapingResult ? (
            <div>
              <p>Available sessions: {scrapingResult.availableSeatsCount}</p>
            </div>
          ) : (
            <p>No scraping data available</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseScrapeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
  
};

export default App;
